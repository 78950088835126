
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useHistory , useLocation } from "react-router-dom";

import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import ButtonSpinner from '../spinners/ButtonSpinner';
import Terms from '../modals/Terms';

import RecaptchaComponent from '../RecaptchaComponent';

import { useTranslation } from 'react-i18next';

import { Formik, Field, Form, ErrorMessage } from 'formik';

import * as Yup from "yup";

import { authenticationService } from '../../services/authenticationService';
import { myListCounterService } from '../../services/myListCounterService';
import { notificationService } from '../../services/notificationService';
import { notificationsPolling } from '../../services/notificationsPolling';

import { SiteConfig }  from '../../SiteConfig';


function RegisterForm(props) {

  const notificationsPollingMode = notificationsPolling();

  const feedbackAreaRef = useRef(null);
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [termsShow, setTermsShow] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [reCaptchaToken, setReCaptchaToken] = useState(null);


  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // redirect to home if already logged in
     if (authenticationService.currentUserValue) {
         history.push('/');
     }
   },[history]);

  const onToken =  useCallback(async(token) => {
    setReCaptchaToken(token);
  },[setReCaptchaToken]);



  const  regenerateToken = () => {
    window.grecaptcha.ready(function() {
      window.grecaptcha
        .execute(SiteConfig.recapchaV3SiteKey, { action: "registerform" })
        .then(token => {
          onToken(token)
        });
    })
  };

  setInterval(function(){regenerateToken();}, 90*1000);


  const toggleTermsShow = () => setTermsShow(!termsShow);

  return (
    <Formik
            initialValues={{
                name: '',
                email: '',
                password: '',
                confirm_password: '',
                accept_terms: false,
                sign_up: false
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string()
                  .required(t('Please enter your name')),
                email: Yup.string()
                    .email(t('Email is invalid'))
                    .required(t('Please enter your email address')),
                password: Yup.string()
                    .min(6, t('Password must be at least 6 characters'))
                    .required(t('Password is required')),
                confirm_password: Yup.string()
                  .oneOf([Yup.ref('password'), null], t('Passwords must match')),
                accept_terms: Yup.bool().oneOf([true], t('Please accept the Terms & Conditions'))
                })}
            onSubmit={fields => {

              setFeedbackText('');

              setSubmitting(true);
              // Fetch the accessToken from the server
              authenticationService.register(fields.name, fields.email, fields.password, reCaptchaToken, fields.sign_up, SiteConfig.siteTag )
                  .then(
                      user => {
                          setSubmitting(false);
                          myListCounterService.getMyListCount();
                          notificationService.getNotifications()
                          //start the notifications polling
                          notificationsPollingMode.running = true;
                          const { from } = location.state || { from: { pathname: "/" } };
                          history.push(from);

                      },
                      error => {

                          setSubmitting(false);
                          setFeedbackText(t('Register error. If you already have an account using this email please try logging in') + '.');
                          window.scrollTo({top: feedbackAreaRef.current.getBoundingClientRect().bottom, behavior: "smooth"});
                          regenerateToken();
                          //setValidated(false);
                      }
                  );
            }}
            >
            {({ errors, status, touched }) => (

                <>
                {feedbackText && <Alert ref={feedbackAreaRef} variant="danger">
                  {feedbackText}
                </Alert>}

                <Form  id={SiteConfig.siteTag + '-account-registration-form'}>
                <div className="form-group">
                    <label htmlFor="name">{t('Name')}</label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-user-circle" aria-hidden="true"></i></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Field
                        name="name"
                        id="name"
                        type="text"
                        className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                         placeholder={t('Enter name')}
                       />
                      <ErrorMessage name="name" component="div" className="invalid-feedback" />
                    </InputGroup>
                </div>
                  <div className="form-group">
                      <label htmlFor="email">{t('Email address')}</label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-envelope" aria-hidden="true"></i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Field
                          name="email"
                          id="email"
                          type="text"
                          className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                           placeholder={t('Enter email')}
                         />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </InputGroup>
                  </div>
                  <div className="form-group">
                      <label htmlFor="password">{t('Password')}</label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-lock" aria-hidden="true"></i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Field
                          name="password"
                          id="password"
                          type="password"
                          className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                          placeholder={t('Password')}
                         />
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                      </InputGroup>
                  </div>
                  <div className="form-group">
                      <label htmlFor="confirm_password">{t('Confirm Password')}</label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-lock" aria-hidden="true"></i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Field
                          name="confirm_password"
                          id="confirm_password"
                          type="password"
                          className={'form-control' + (errors.confirm_password && touched.confirm_password ? ' is-invalid' : '')}
                          placeholder={t('Confirm Password')}
                         />
                        <ErrorMessage name="confirm_password" component="div" className="invalid-feedback" />
                      </InputGroup>
                  </div>
                  <div className="form-group form-check mt-4 mb-2">
                      <Field type="checkbox" id="sign_up" name="sign_up" className={'form-check-input'} />
                      <label htmlFor="sign_up" className="form-check-label">{t('Please sign me up for the newsletter')}</label>
                  </div>
                  <div className="form-group form-check mt-2 mb-4">
                      <Field type="checkbox" id="accept_terms" name="accept_terms" className={'form-check-input ' + (errors.accept_terms && touched.accept_terms ? ' is-invalid' : '')} />
                      <label htmlFor="accept_terms" className="form-check-label">{t('I agree to the')} <span className="blue pointer underline" onClick={(e)  => {e.preventDefault();toggleTermsShow()}}>{t('Terms & Conditions')}</span></label>
                      <ErrorMessage name="accept_terms" component="div" className="invalid-feedback" />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary mr-2" disabled={submitting || !reCaptchaToken}>{t('Create Account')}  {submitting && <ButtonSpinner/>}</button>
                  </div>
              </Form>
              <RecaptchaComponent action="registerform" onToken={onToken} sitekey={SiteConfig.recapchaV3SiteKey}/>
              <Terms show={termsShow} handleClose={toggleTermsShow}/>
              <p>
              <Link
                to="/forgotten-password"
              >{t('Forgot your password?')}</Link>
              </p>
              <div className="row">
                <div className="form-group text-center col-md-12">
                  <small>{t('This site is protected by reCAPTCHA and the Google')}&nbsp;
                      <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">{t('Privacy Policy')}</a> {t('and')}&nbsp;
                      <a target="_blank" rel="noreferrer" href="https://policies.google.com/terms">{t('Terms of Service')}</a> {t('apply')}.
                  </small>
                </div>
              </div>
              </>
            )}
       </Formik>



  );
};

export default RegisterForm;
