import React, { useEffect , useState} from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ErrorModal from '../modals/ErrorModal';
import Helpers from '../Helpers'
import { useTranslation } from 'react-i18next';

import { requirementsService } from '../../services/requirementsService';
import { authenticationService } from '../../services/authenticationService';
import MarkdownView from 'react-showdown';

function RequirementDetailsModal(props) {



  useEffect(() => {
    const fetchData = async () => {
      handleShow();
      requirementsService.getRequirementById(props.req_id)
        .then(
            (data) => {
              setRequirement(data.requirement);
            },
            error => {
              if (error.message === '401') {
                authenticationService.logout();
              } else if (error.message === '500') {
                setShow(false);
                setShowErrorModal(true);
              }
            }
        );

    };
    if (props.req_id)
      fetchData();
  }, [props.req_id,props.req_id_timestamp]);

  const [requirement, setRequirement] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [show, setShow] = useState(false);

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const handleClose = () =>
  {
    setShow(false);
    setRequirement(null);
  }
  const handleShow = () => setShow(true);
  const { t } = useTranslation();

  const {arrayAsBadges} = Helpers();

  return (
    <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      <Modal
        animation={false}
        show={show }
        onHide={handleClose}
        backdrop="static"
        size="lg"
      >
        { requirement ?
        <>
        <Modal.Header closeButton>
          <Modal.Title>
            {requirement?.standard && <><h3>{requirement?.standard.number} - {requirement?.standard.title}</h3>
            <h4>{t('Edition')}&nbsp;{requirement?.standard.edition},&nbsp;{t('Amendment')}&nbsp;{requirement?.standard.Amendment}</h4></>}
            <h4>{t('Section')}&nbsp;{requirement?.section}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre className="md-table">
            <MarkdownView
              markdown={requirement?.text}
              options={{ tables: true, emoji: false }}
            />
          </pre>


          { requirement?.topic.length > 0 &&
            <h4>{t('Topics')}&nbsp;{requirement?.topic && arrayAsBadges(requirement?.topic.map(item => {
                return item.title;
            }) ,'primary')}</h4>
          }
          { requirement?.importance.length > 0 &&
            <h4>{t('Degree of Compliance')}&nbsp;{requirement?.importance && arrayAsBadges(requirement?.importance.split(','),'importance')}</h4>
          }
          { requirement?.stakeholder.length > 0 &&
            <h4>{t('Stakeholders')}&nbsp;{requirement?.stakeholder && arrayAsBadges(requirement?.stakeholder.map(item => {
                return item.title;
            }) ,'primary')}</h4>
          }
          { requirement.qmp.length > 0 &&
            <h4>{t('QM Principles')}&nbsp;{requirement?.qmp && arrayAsBadges(requirement?.qmp.map(item => {
              return item.title;
            }) ,'primary')}</h4>
          }
          { requirement.rme.length > 0 &&
            <h4>{t('RM Elements')}&nbsp;{requirement?.rme && arrayAsBadges(requirement?.rme.map(item => {
                return item.title;
            }) ,'primary')}</h4>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Close')}
          </Button>
        </Modal.Footer>
        </> :
          <LoadingSpinner/>
        }
      </Modal>
    </>
  );
}

export default RequirementDetailsModal
