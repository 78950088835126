import  { useEffect} from 'react';
import { singletonHook } from 'react-singleton-hook';
import { notificationService } from './notificationService';
import { apiService } from './apiService';

//const api = { async getMe() { return { name: 'test' }; } };

const init = { running: true };

const useNotificationsPollingImpl = () => {

  useEffect(() => {

    const retrieveData  =  () => {

      if (init.running) {
        apiService.apiGet(process.env.REACT_APP_API_URL + '/notify/get')
          .then(
              (data) => {
                notificationService.setNotifications({notifications: data.items, getNotifications : data.getNotifications});
              },
              error => {
                if (error.message === '401'
                || error.message === '500') {
                  init.running = false;
                }
              }
          );
      }
    }

    retrieveData();
    setInterval(retrieveData,process.env.REACT_APP_NOTIFICATIONS_POLL_INTERVAL_MILLISECONDS);
  }, []);

  return init;
};

export const notificationsPolling = singletonHook(init, useNotificationsPollingImpl);
