
function LoadingSpinner(props) {

  const spinnerStyle = {
    height: props.fixedHeight ? props.fixedHeight + 'px' : 'calc(100vh - ' + String(props.heightOffset ? props.heightOffset : 0) + 'px)'
  };

  return (

  <div className="container">
    <div className="row justify-content-center align-items-center" style={spinnerStyle}>
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>

  )
};


export default LoadingSpinner;
