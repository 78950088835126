import React, { useEffect , useState, useRef, useCallback} from "react";
import RequirementsPagination from './RequirementsPagination';

import { Link, useHistory  } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ListSaveToFormModal from '../modals/ListSaveToFormModal';
import ListFormModal from '../modals/ListFormModal';
import ButtonSpinner from '../spinners/ButtonSpinner';
import ErrorModal from '../modals/ErrorModal';
import MyListCSVDownload from '../MyListCSVDownload'
import Helpers from '../Helpers'
import { useTranslation } from 'react-i18next';
import MarkdownView from 'react-showdown';

import RequirementDetailsCarousel from '../modals/RequirementDetailsCarousel';

import SortableTableHeader from './SortableTableHeader';
import { requirementsService } from '../../services/requirementsService';
import { authenticationService } from '../../services/authenticationService';

import { SiteConfig }  from '../../SiteConfig';

export default function RequirementsSelectedListGrid(props) {

  const upperPaginationRef = useRef(null);

  const [requirements, setRequirements] = useState([]);
  const [sortColumn, setSortColumn] = useState('standard_number');
  const [sortType, setSortType] = useState('asc');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(process.env.REACT_APP_REQUIREMENTS_RESULTS_PER_PAGE);
  const [pagesTotal, setPagesTotal] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(null);

  const [rowUpdating, setRowUpdating] = useState([]);
  const [remove_all_updating, setRemove_all_updating] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [canExportList, setCanExportList] = useState(false);
  const [canViewLinkedRequirements, setCanViewLinkedRequirements] = useState('false');
  const [showListSaveToFormModal, setShowListSaveToFormModal] = useState(false);
  const [showListFormModal, setShowListFormModal] = useState(false);
  const [selectedListReqIds, setSelectedListReqIds] = useState([]);
  const [canCreateLists, setCanCreateLists] = useState(false);
  const [listName, setListName] = useState("");
  const [selected_req_id, setSelected_req_id] = useState(null);
  const [selected_req_id_timestamp, setSelected_req_id_timestamp] = useState(null);

  const { t } = useTranslation();
  const {arrayAsBadges, docTypeIdToDocType} = Helpers();
  const history = useHistory();

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
    setPage(1);
    return true;
  }

  const lowerPageSizeEventHandler  = (page_size) => {
    window.scrollTo({top: upperPaginationRef.current.getBoundingClientRect().bottom, behavior: "smooth"});
    pageSizeEventHandler(page_size, true);
  }

  const pageSizeEventHandler  = (page_size, scrollToTop) => {
    setPageSize(page_size);
    setPage(1);
  }

  const lowerPaginationEventHandler  = (page_size) => {
    window.scrollTo({top: upperPaginationRef.current.getBoundingClientRect().bottom, behavior: "smooth"});
    paginationEventHandler(page_size, true);
  }

  const paginationEventHandler  = (page, scrollToTop) => {
    setPage(parseInt(page, 10));
  }

  const onCloseShowListSaveToFormModalCallback = (reloadData,showAddListModal) => {
    setShowListSaveToFormModal(false);
    setShowListFormModal(showAddListModal);
    if (reloadData)
      retrieveData();

  }

  const showListSaveToForm = (e,req_id) => {
    e.preventDefault();
    e.stopPropagation();
    setShowListSaveToFormModal(true);
    setSelectedListReqIds([req_id]);
  }

  const onCloseListFormModalCallback = (reloadData) => {
    setShowListFormModal(false);
    setShowListSaveToFormModal(true);
  }

  const  navigateToLinkPage= (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    history.push('/links/' + id);
  }

  const handleRemoveClick = (e, id) => {

    e.preventDefault();
    e.stopPropagation();

    let newRowUpdating = [...rowUpdating];
    newRowUpdating[id] = true;
    setRowUpdating(newRowUpdating);
    setLoadingData(true);

    requirementsService.deleteFromList(props.list_id, id, SiteConfig.docType)
      .then(
          (data) => {

            //newRowUpdating[id] = true;
            setLoadingData(false);
            setRowUpdating(newRowUpdating);
            let updatedRequirements = requirements.filter(x=> x.id !== id);

            receiveListInfo(listName, recordsTotal - 1);
            setRecordsTotal(recordsTotal - 1);

            if (0 === updatedRequirements.length) {
              if (pagesTotal === page) {
                setPage(page-1);
              } else {
                //trigger a fetch
                setPage(0);
                setPage(1);
              }
            } else {
              setRequirements(updatedRequirements);
            }
          },
          error => {
            if (error.message === '401') {
              authenticationService.logout();
            } else if (error.message === '500') {
              setLoadingData(false);
              setShowErrorModal(true);
            }
          }
      );
  }

  const handleRemoveAllClick  = (e) => {
    e.preventDefault();
    e.stopPropagation()

    let row_updating = [...rowUpdating];
    let clnRequirements = [...requirements];

    let requirementIds = clnRequirements.map(item => {
        //row_updating[item.id] = true;
        return item.id;
    });

    clnRequirements.forEach((item, i) => {
      row_updating[item.id] = true;
    });

    setRemove_all_updating(true);
    setRowUpdating(row_updating);
    setLoadingData(true);

    requirementsService.deleteFromList(props.list_id, requirementIds)
      .then(
          (data) => {
            clnRequirements.forEach((item, i) => {
              row_updating[item.id] = false;
            });

            setRemove_all_updating(false);
            setRowUpdating(row_updating);

            if (pagesTotal === page) {
              setPage(page-1);
            } else {
              //trigger a fetch
              setPage(0);
              setPage(page);
            }
          },
          error => {
            if (error.message === '401') {
              authenticationService.logout();
            } else if (error.message === '500') {
              setShowErrorModal(true);
            }
          }
      );
  }

  const receiveListInfo = props.receiveListInfo;
  const list_id = props.list_id;

  const retrieveData  =  useCallback(async () => {

      setLoadingData(true);
        requirementsService.postSelectedList(list_id, sortColumn, sortType, page, SiteConfig.docType, pageSize)
        .then(
            (result) => {
                setInitiallyLoaded(true);
                setRequirements(result.requirements.data);
                setPagesTotal(result.requirements.last_page);
                setRecordsTotal(result.requirements.total);
                setCanExportList(result.canexportlist);
                setListName(result.listName);
                receiveListInfo(result.listName, result.requirements.total);
                setCanCreateLists(result.canCreateLists);
                setCanViewLinkedRequirements(result.isAmasAdmin);
                setLoadingData(false);
            },
            error => {
              if (error.message === '401') {
                authenticationService.logout();
              } else if (error.message === '500') {
                setShowErrorModal(true);
              }
            }
        );
      }
  ,[receiveListInfo,list_id, sortColumn, sortType, page, pageSize]);

  useEffect(() => {
    retrieveData();
  }, [retrieveData]);

  return (

      <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      <ListSaveToFormModal show={showListSaveToFormModal} list_id={props.list_id} req_ids={selectedListReqIds} handleModalOpenClose={onCloseShowListSaveToFormModalCallback} />
      <ListFormModal show={showListFormModal}  handleModalClose={onCloseListFormModalCallback} />

      { initiallyLoaded && loadingData &&
        <ModalSpinner/>
      }
      { initiallyLoaded  ?
        <>
          <div className="container">
            { recordsTotal > 0 ?
            <><div className="col d-flex justify-content-center mb-3 d-print-none">
                <Button
                  className="mr-2"
                  variant="outline-primary"
                    onClick={(e) =>
                      {
                        window.print();
                      }
                    }
                  ><i className="fa fa-print"></i> {t('Print')}
                </Button>
                { canExportList && <MyListCSVDownload list_id={props.list_id} disabled={false}/>}
              </div>

              <div className="col pt-2 mt-2 d-print-none">
                <Button
                  className="pull-right hasSpinner"
                  variant="danger"
                  size="sm"
                  onClick={(e) => handleRemoveAllClick(e)}
                  disabled={remove_all_updating}
                >
                  {t('Remove all')}
                  {(remove_all_updating) && <ButtonSpinner/>}
                </Button>
                <div
                  ref={upperPaginationRef}>
                  <RequirementsPagination onPageSizeChange={pageSizeEventHandler} onItemClick={paginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize}/>
                </div>
              </div>
              <div className="col">

                <div>
                  <div className="flex-table header " role="rowgroup">
                    <SortableTableHeader addClass="module" onHeaderClick={handleSortColumn} field="module_number" label={t('DocType') + ' #'} sort_column={sortColumn} sort_type={sortType}/>
                    <SortableTableHeader addClass="section" onHeaderClick={handleSortColumn} field="section" label={t('Section')} sort_column={sortColumn} sort_type={sortType}/>
                    <div className="flex-row importance" role="columnheader">
                      <strong>
                        {t('Degree of Compliance')}
                      </strong>
                    </div>
                    <SortableTableHeader addClass="text flex-grow-1" onHeaderClick={handleSortColumn} field="text" label={t('Text')} sort_column={sortColumn} sort_type={sortType}/>
                    <div className="flex-row actions" role="columnheader">
                      <strong>
                        {t('Save')}
                      </strong>
                    </div>
                  </div>
                  {
                  0 < requirements.length ?
                    requirements.map((value, index) => {
                      return (
                              <div
                                className="flex-table table-row pointer"
                                role="rowgroup"
                                key={ index + 'data' }
                                onClick={() => {
                                  //need to set a timestamp just in case the same row is clicked twice
                                  setSelected_req_id(value.id);
                                  setSelected_req_id_timestamp( new Date().valueOf());


                                }}>
                                  <div
                                    className="flex-row module  text-break"
                                    role="cell"
                                  >
                                   <>
                                     <div className="hidden-tablet-landscape">
                                       <OverlayTrigger
                                         key={value.id}
                                         placement="top"
                                         overlay={
                                           <Popover>
                                             <Popover.Content>
                                               <pre>{value.standard.title}</pre>
                                             </Popover.Content>
                                           </Popover>
                                         }
                                         >
                                         <span>{docTypeIdToDocType(value.standard.doc_type)} {value.standard.number}</span>
                                       </OverlayTrigger>
                                     </div>
                                     <div className="visible-tablet-landscape">
                                       <div>{t('Standard')}</div>
                                       <span><strong>{docTypeIdToDocType(value.standard.doc_type)} </strong>:{value.standard_number} {value.standard.title}</span>
                                     </div>
                                   </>
                                  </div>
                                  <div
                                    className="flex-row section  text-break "
                                    role="cell"
                                  >{value.section}</div>
                                  <div
                                    className="flex-row importance"
                                    role="cell"
                                  >
                                  {arrayAsBadges(value.importance.split(','),'importance')}
                                  </div>
                                  <div
                                    className="flex-row text flex-grow-1"
                                    role="cell"
                                  >
                                    <>
                                      <div className="hidden-tablet-landscape">
                                      <OverlayTrigger
                                        key={`text${value.id}`}
                                        placement="top"
                                        overlay={
                                          <Popover className="wide">
                                            <Popover.Content>
                                                <pre className="md-table">
                                                  <MarkdownView
                                                    markdown={value.text}
                                                    options={{ tables: true, emoji: false }}
                                                  />
                                                </pre>
                                            </Popover.Content>
                                          </Popover>
                                        }
                                        >
                                        <span className="md-table">
                                          <MarkdownView
                                            markdown={value.text}
                                            options={{ tables: true, emoji: false }}
                                          />
                                        </span>
                                      </OverlayTrigger>
                                      </div>
                                      <div className="visible-tablet-landscape md-table">
                                        <MarkdownView
                                          markdown={value.text}
                                          options={{ tables: true, emoji: false }}
                                        />
                                      </div>
                                    </>
                                  </div>
                                  <div
                                    className="flex-row actions"
                                    role="cell"
                                  >
                                  <>
                                   <Button
                                     className="w-100 hasSpinner"
                                     variant="danger"
                                     size="sm"
                                     onClick={(e) => handleRemoveClick(e,value.id)}
                                     disabled={rowUpdating[value.id] || false}>
                                       {t('Remove')}
                                       {(rowUpdating[value.id] || false) && <ButtonSpinner/>}
                                   </Button>
                                   { canCreateLists &&
                                   <Button
                                     className="mt-2 w-100"
                                     variant="primary"
                                     size="sm"
                                     onClick={(e) => showListSaveToForm(e,value.id)}
                                     >
                                     {t('Move')}
                                   </Button>
                                   }
                                   { canViewLinkedRequirements && SiteConfig.siteTag !== 'iddrs' && 'lbnmas' !== SiteConfig.siteTag  &&
                                     <Button
                                       className="mt-2 w-100"
                                       variant="success"
                                       size="sm"
                                       onClick={(e) => navigateToLinkPage(e,value.id)}
                                       disabled={!value.hasLink}>
                                         {t('Links')}
                                     </Button>
                                   }
                                 </>
                                </div>
                              </div>)
                            ;
                    })
                    : <div className="flex-table table-row w-100">
                      <div className="flex-row flex-grow-1">{t('No requirements')}</div>
                    </div>
                  }
                </div>
              </div>
              <div className="col pt-2 mt-2 d-print-none">
                <Button
                  className="pull-right"
                  variant="danger"
                  size="sm"
                  onClick={(e) => handleRemoveAllClick(e)}
                  disabled={remove_all_updating}
                >
                  {t('Remove all')}
                  {(remove_all_updating) && <ButtonSpinner/>}
                </Button>
                <RequirementsPagination onPageSizeChange={lowerPageSizeEventHandler} onItemClick={lowerPaginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize}/>
              </div>
              <RequirementDetailsCarousel list_id={props.list_id} sort_column={sortColumn}  sort_type={sortType} page={page} page_size={pageSize} req_id={selected_req_id} req_id_timestamp={selected_req_id_timestamp}/>

            </>
            :
              <div className="col">{t('No List Items')} <Link className="underline" to="/search">{t('Search page')}</Link>.</div>
            }
          </div>
        </>
      :
     <LoadingSpinner heightOffset={200}/>
    }

  </>

  );
}
