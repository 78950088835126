import React, { useEffect , useState} from "react";
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ErrorModal from '../modals/ErrorModal';
import { useTranslation } from 'react-i18next';
import NotificationsGridSingle from './NotificationsGridSingle';
import { notificationService } from '../../services/notificationService';
import { apiService } from '../../services/apiService';

export default function NotificationsGrids(props) {

  const [readNotifications, setReadNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [recordsTotal, setRecordsTotal] = useState(null);

  const [loadingData, setLoadingData] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const { t } = useTranslation();

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  useEffect(() => {


    apiService.apiGet(process.env.REACT_APP_API_URL + '/notify/get')
      .then(
          (data) => {
            setInitiallyLoaded(true);

            let unReadNotifications = data.items.filter(x=> !x.read_at);
            let readNotifications = data.items.filter(x=> x.read_at);

            //debugger;
            setReadNotifications(readNotifications);
            setUnreadNotifications(unReadNotifications);

            //mark all the unread notifications
            //as read
            //notificationService.setNotifications([]);
            unReadNotifications.forEach(x => {

              notificationService.markNotificationAsReadAPI(x.id)
              .then(
                  (data) => {},
                  error => {}
                )
            });

            notificationService.setNotifications({notifications: [], getNotifications: notificationService.currentNotificationsValue.getNotifications});
            setRecordsTotal(data.items.length);
            setLoadingData(false);
          },
          error => {
            if (error.message === '401'
            || error.message === '500') {
              setShowErrorModal(true);
            }
          }
      );


  },[]);


  return (

      <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      { initiallyLoaded && loadingData &&
        <ModalSpinner/>
      }
      { initiallyLoaded  ?
        <>

        <div className="pb-4">
            { recordsTotal > 0 ?
            <>
              <h3>{t('Unread')}</h3>
              <NotificationsGridSingle notifications={unreadNotifications}/>

              <h3>{t('Read')}</h3>
              <NotificationsGridSingle notifications={readNotifications}/>

            </>
            :
              <>{t('No notifications')}.</>
            }
          </div>
        </>
      :
     <LoadingSpinner heightOffset={200}/>
    }

  </>

  );
}
