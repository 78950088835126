import React, { useEffect , useState} from "react";
import { useHistory } from "react-router-dom";
import { notificationService } from '../services/notificationService';
import Toast from 'react-bootstrap/Toast';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Notifications(props) {

  const { t } = useTranslation();
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [getNotifications, setGetNotifications] = useState(false);

  const history = useHistory();

  const toggleShowNotifications = () =>
    {
      setShowNotifications(!showNotifications);
    }

  useEffect(() => {
    const subscription = notificationService.currentNotifications.subscribe(data => {
      setUnreadNotifications(data.notifications.filter(x=> !x.read_at));
      setGetNotifications(data.getNotifications);
    });

    return () => {
      subscription.unsubscribe();
    }
  },[]);

  const selectNotification = (notification_id) => {
   history.push('/notifications/' + notification_id);
   setShowNotifications(false);
  }


  return (
    <>
      { getNotifications &&
      <>
        <div
        aria-live="polite"
        aria-atomic="true"
        className="position-relative pr-4"
        >
         <div className="visible-tablet-landscape">
           <Link className="pointer" onClick={() => props.setNavExpanded(false)} to="/notifications">
             <span className="fa-stack danger" data-count={unreadNotifications.length}>
               <i className="fa fa-bell-o fa-stack-1x fa-inverse"></i>
             </span>
           </Link>
         </div>
         <div className="hidden-tablet-landscape">
          <div className="pointer" onClick={toggleShowNotifications}>
            <span className="fa-stack danger" data-count={unreadNotifications.length}>
              <i className="fa fa-bell-o fa-stack-1x fa-inverse"></i>
            </span>
          </div>
        </div>
          <div
            className="position-absolute d-flex justify-content-end"
            style={{
              right: '1em',
              width:'30em',
              top: '2.5em'
            }}
          >
            <Toast
              className="notications"
              show={showNotifications}
              onClose={toggleShowNotifications}>

             <Toast.Body>
                <ul>
                { 4 < unreadNotifications.length &&
                  <li class="no-hover">
                   <Link
                     className="btn btn-primary btn-lg w-100"
                     to={'/notifications/'}
                     onClick={() => setShowNotifications(false)}
                   >
                     {t('View all notifications')}
                   </Link>
                  </li>
                }
                {unreadNotifications.sort((a, b) => (new Date(a.updated_at).valueOf  > new Date(b.updated_at).valueOf ) ? -1 : 1).map(function(value, index){
                   return <li
                            key={ index }
                            onClick={() => selectNotification(value.id)}
                            className="pointer"
                          >
                            <p className="pointer"><span className="heading">{value.data.req?.doc_type_text} {t('updated')}</span></p>
                            <p className="pointer">{value.data.message}&nbsp;
                              <span className="pointer link-color hover-underline">{t('Click here to view')}.</span>
                            </p>
                          </li>;
                 })}
                 <li>
                  <Link
                    className="btn btn-primary btn-lg w-100"
                    to={'/notifications/'}
                    onClick={() => setShowNotifications(false)}
                  >
                    {t('View all notifications')}
                  </Link>
                 </li>
              </ul>

             </Toast.Body>
            </Toast>
          </div>
        </div>
      </>
      }
    </>

  );
};

export default Notifications;
