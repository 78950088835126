import { apiService } from './apiService';

export const requirementsService = {
    getSearchFilters,
    requirements,
    getRequirementById,
    getLinkedRequirementsById,
    getList,
    getListExport,
    postList,
    postSelectedList,
    postDetailedList,
    putToList,
    deleteFromList,
};

function getSearchFilters(doc_type, sitetag) {

  let data = new FormData();
  data.append('site_tag',sitetag);
  data.append('doc_type',doc_type);

  return  Promise.all([
      apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_dropdown_topics',data),
      apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_dropdown_stakeholders',data),
      apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_dropdown_rmes',data),
      apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_dropdown_qmps',data),
      apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_dropdown_standards' ,data)
 ])
   .then(([topics, stakeholders, rmes, qmps, standards]) => {
     return {
       topics,
       stakeholders,
       rmes,
       qmps,
       standards
     };
   })
}

function requirements(data) {
  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/reqs',data);
}

function getRequirementById(id) {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/req/'+ id);
}

function getLinkedRequirementsById(id) {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/links/'+ id);
}

function getList() {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/cm_list');
}

function getListExport(list_id,doc_type, site_tag) {

  let data = new FormData();
  data.append('list_id',list_id);
  data.append('doc_type',doc_type);
  data.append('site_tag',site_tag);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list/export',data);
}

function postList(sortColumn,sortType,page,doc_type,page_size) {

  let data = new FormData();
  data.append('sort_column',sortColumn);
  data.append('sort_type',sortType);
  data.append('selected_page',page);
  data.append('doc_type',doc_type);
  data.append('page_size',page_size);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list',data);
}

function postSelectedList(list_id, sortColumn,sortType,page,doc_type,page_size) {

  let data = new FormData();
  data.append('sort_column',sortColumn);
  data.append('sort_type',sortType);
  data.append('selected_page',page);
  data.append('doc_type',doc_type);
  data.append('page_size',page_size);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list/view/' + list_id,data);
}

function postDetailedList(list_id, sortColumn,sortType,page,doc_type,page_size) {

  let data = new FormData();
  data.append('list_id',list_id);
  data.append('sort_column',sortColumn);
  data.append('sort_type',sortType);
  data.append('selected_page',page);
  data.append('doc_type',doc_type);
  data.append('page_size',page_size);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list/detailed',data);
}

function putToList(ids,doc_type) {
  let data = new FormData();
  data.append('req_ids',ids);
  data.append('doc_type',doc_type);
  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list/items/attach', data);
}

function deleteFromList(list_id, ids,doc_type) {
  let data = new FormData();
  data.append('list_id',list_id);
  data.append('req_ids',ids);
  data.append('doc_type',doc_type);
  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list/items/detach', data);
}
