import React, { useEffect , useState, useCallback} from "react";
import { useHistory } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ErrorModal from '../modals/ErrorModal';
import ListFormModal from '../modals/ListFormModal';
import ListDeleteModal from '../modals/ListDeleteModal';
import { useTranslation } from 'react-i18next';
import { listsService } from '../../services/listsService';
import { authenticationService } from '../../services/authenticationService';
import { SiteConfig }  from '../../SiteConfig';

export default function RequirementLinksGrid(props) {

  const [lists, setLists] = useState([]);
  const [listRecordsTotal, setListRecordsTotal] = useState(null);
  const [selectedListId, setSelectedListId] = useState(null);
  const [loadingListData, setLoadingListData] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showListFormModal, setShowListFormModal] = useState(false);
  const [showListDeleteFormModal, setShowListDeleteFormModal] = useState(false);
  const [listsInitiallyLoaded, setListsInitiallyLoaded] = useState(false);
  const [canCreateLists, setCanCreateLists] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const addToClipboard = (e,sharePath) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(window.location.protocol + "//" + window.location.host + sharePath);
  }

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const onCloseListFormModalCallback = (reloadData) => {
    setShowListFormModal(false);
    if (reloadData)
      retrieveListData();
  }

  const onCloseListDeleteFormModalCallback = (reloadData) => {
    setShowListDeleteFormModal(false);
    if (reloadData)
      retrieveListData();
  }

  const showListForm = (e,list_id) => {

    e.preventDefault();
    e.stopPropagation();
    setShowListFormModal(true);
    setSelectedListId(list_id);
  }

  const showListDeleteForm = (e,list_id) => {
    e.preventDefault();
    e.stopPropagation();
    setShowListDeleteFormModal(true);
    setSelectedListId(list_id);
  }

  const selectList = (list_id) => {
   history.push('/mylists/' + list_id);
  }

  const retrieveListData  = useCallback(async () => {

      setLoadingListData(true);
        listsService.getLists(SiteConfig.docType)
        .then(
            (result) => {

                setListsInitiallyLoaded(true);
                setLoadingListData(false);
                setLists(result.lists);
                setListRecordsTotal(result.lists.length);
                setCanCreateLists(result.canCreateLists);

            },
            error => {

              if (error.message === '401' ) {
                authenticationService.logout();
              } else if (error.message === '500') {
                setShowErrorModal(true);
              } else if (error.message === '403') {
                history.push('/');
              }

            }
        );

  },[history]);

  useEffect(() => {
    retrieveListData();

  },[retrieveListData]);



  return (

      <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      <ListFormModal show={showListFormModal} list_id={selectedListId} handleModalClose={onCloseListFormModalCallback} />
      <ListDeleteModal show={showListDeleteFormModal} list_id={selectedListId} handleModalClose={onCloseListDeleteFormModalCallback} />
      { listsInitiallyLoaded && loadingListData &&
        <ModalSpinner/>
      }
      { listsInitiallyLoaded  ?
        <>

        <div className="pb-4">
            { listRecordsTotal > 0 ?
            <>


              <div className="row">
                <div className="col">
                { canCreateLists &&
                  <Button
                    className="pull-right mb-3"
                    onClick={(e) => showListForm(e)}
                    variant="primary"
                  >{t('New list')}
                  </Button>
                }
                </div>
              </div>
              <Table striped bordered hover className="custom-responsive">
                <thead>
                  <tr>
                    <th>{t('#')}</th>
                    <th>{t('List name')}</th>
                    { canCreateLists &&
                      <th></th>
                    }
                  </tr>
                </thead>
                <tbody>
                {lists.sort((a, b) => a.id - b.id).map(function(value, index){
                  return( <tr
                      key={ index }
                      className="pointer"
                      onClick={() => selectList(value.id)}
                        >
                      <td>{index+1}</td>
                      <td>
                        <span
                          className="position-relative after-ml-1"
                          data-count={value.get_mylist_count} >
                          {value.list_name}
                        </span>
                      </td>
                      { canCreateLists &&
                        <td>
                          <ButtonGroup
                              className="pull-right-desktop"
                              aria-label="{t('List actions')}"
                            >
                            <OverlayTrigger
                              key={value.id}
                              placement="top"
                              rootClose={false}
                              rootCloseEvent="click"
                              trigger="click"
                              overlay={
                                <Popover >
                                  <Popover.Content>
                                    <Form>
                                      <Form.Text className="mb-1">
                                        {t('Added to Clipboard!')}
                                      </Form.Text>
                                      <Form.Control
                                        className="mr-2"
                                        type="text"
                                        value={ window.location.protocol + "//" + window.location.host +  value.share_link}/>
                                      <Form.Text>
                                        {t('Highlight text above and copy if clipboard is empty')}
                                      </Form.Text>
                                    </Form>
                                  </Popover.Content>
                                </Popover>
                              }
                              >
                              <Button
                                onClick={(e) => addToClipboard(e,value.share_link)}
                                variant="primary"
                              >{t('Share')}</Button>
                            </OverlayTrigger>
                            <Button
                              onClick={(e) => showListForm(e,value.id)}
                              variant="success"
                            >{t('Rename')}</Button>
                            <Button
                              onClick={(e) => showListDeleteForm(e,value.id)}
                              variant="danger"
                              >{t('Delete')}</Button>
                          </ButtonGroup>
                        </td>
                      }

                    </tr>)
                  })
                }
                </tbody>
              </Table>

            </>
            :
              <>{t('No lists')}.</>
            }
          </div>
        </>
      :
     <LoadingSpinner heightOffset={200}/>
    }

  </>

  );
}
