import React, { useEffect , useState} from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next';
import ListDeleteForm from '../forms/ListDeleteForm'

function ListDeleteModal(props) {

  useEffect(() => {
      setShow(props.show);
  }, [props.show]);

  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleFormSubmit = (reloadData) => {
    props.handleModalClose(reloadData)
  }

  return (

      <Modal
        animation={true}
        show={show }
        onHide={() => props.handleModalClose(false) }
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>{t('Delete List')}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListDeleteForm list_id={props.list_id} onSuccessfulFormSubmit={handleFormSubmit}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.handleModalClose(false)}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>

  );
}

export default ListDeleteModal;
