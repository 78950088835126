import React, { useEffect , useState} from "react";
import { useHistory } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import ButtonSpinner from '../spinners/ButtonSpinner';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { authenticationService } from '../../services/authenticationService';
import { myListCounterService } from '../../services/myListCounterService';

function ResetPasswordForm(props) {

  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const history = useHistory();

  useEffect(() => {
    // redirect to home if already logged in
     if (authenticationService.currentUserValue) {
         history.push('/');
     }
   },[history]);



  return (
    <Formik
            initialValues={{
                email: '',
                password: '',
                confirm_password: ''
            }}
            validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email(t('Email is invalid'))
                      .required(t('Please enter your email address')),
                    password: Yup.string()
                        .min(6, t('Password must be at least 6 characters'))
                        .required(t('Password is required')),
                    confirm_password: Yup.string()
                      .oneOf([Yup.ref('password'), null], t('Passwords must match')),
            })}
            onSubmit={fields => {
              setFeedbackText('');
              setSubmitting(true);
              // Fetch the accessToken from the server
              authenticationService.resetPassword(props.token,fields.email,fields.password,fields.confirm_password)
                  .then(
                      data => {
                        setSubmitting(false);
                        myListCounterService.getMyListCount();
                        const { from } =  { from: { pathname: "/" } };
                        history.push(from);
                      },
                      error => {
                        setSubmitting(false);
                        setFeedbackText(t('There was a problem with the password reset, did you enter the correct details?'));
                      }
                  );
            }}
            >
            {({ errors, status, touched }) => (

                <>
                {feedbackText && <Alert variant="danger">
                  {feedbackText}
                </Alert>}

                <Form>
                  <div className="form-group">
                      <label htmlFor="email">{t('Email address')}</label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-envelope" aria-hidden="true"></i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Field
                          name="email"
                          type="text"
                          className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                           placeholder={t('Enter email')}
                         />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </InputGroup>
                  </div>
                  <div className="form-group">
                      <label htmlFor="password">{t('Password')}</label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-lock" aria-hidden="true"></i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Field
                          name="password"
                          id="password"
                          type="password"
                          className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                          placeholder={t('Password')}
                         />
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                      </InputGroup>
                  </div>
                  <div className="form-group">
                      <label htmlFor="confirm_password">{t('Confirm Password')}</label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-lock" aria-hidden="true"></i></InputGroup.Text>
                        </InputGroup.Prepend>

                        <Field
                          name="confirm_password"
                          id="confirm_password"
                          type="password"
                          className={'form-control' + (errors.confirm_password && touched.confirm_password ? ' is-invalid' : '')}
                          placeholder={t('Confirm Password')}
                         />
                        <ErrorMessage name="confirm_password" component="div" className="invalid-feedback" />
                      </InputGroup>
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-primary mr-2" disabled={submitting}>{t('Reset password')}  {submitting && <ButtonSpinner/>}</button>
                  </div>


                  <div className="row">
                    <div className="form-group text-center col-md-12">
                      <small>This site is protected by reCAPTCHA and the Google&nbsp;
                          <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
                          <a target="_blank" rel="noreferrer" href="https://policies.google.com/terms">Terms of Service</a> apply.
                      </small>
                    </div>
                  </div>
              </Form>

                </>
            )}
       </Formik>



  );
};

export default ResetPasswordForm;
