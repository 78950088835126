import React, { useEffect , useState} from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ErrorModal from '../modals/ErrorModal';
import Helpers from '../Helpers'
import { useTranslation } from 'react-i18next';

import { requirementsService } from '../../services/requirementsService';
import { authenticationService } from '../../services/authenticationService';
import MarkdownView from 'react-showdown';

import { SiteConfig }  from '../../SiteConfig';


function RequirementDetailsCarousel(props) {
  //const [items, setItems] = useState([]);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
   setIndex(selectedIndex);
  };

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [show, setShow] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [requirements, setRequirements] = useState([]);

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const handleClose = () =>
  {
    setShow(false);
  }

  const handleShow = () => setShow(true);

  const {arrayAsBadges} = Helpers();
  const { t } = useTranslation();

  const useModifiedData = (sortColumn, sortType, page, pageSize) => {

    const [modifiedData, setModifiedData] = useState([])

    useEffect(() => {

      const retrieveData  = async () => {

          setLoadingData(true);
            requirementsService.postDetailedList(props.list_id, sortColumn, sortType, page, SiteConfig.docType, pageSize)
            .then(
                (result) => {
                    setLoadingData(false);
                    setRequirements(result.requirements.data);

                    let items = [];

                    result.requirements.data.forEach((requirement, i) => {

                      items.push(
                        <Carousel.Item
                          key={requirement.id}
                          >
                          {requirement?.standard && <><h3>{requirement?.standard.number} - {requirement?.standard.title}</h3>
                            <h4>{t('Edition')}&nbsp;{requirement?.standard.edition},&nbsp;{t('Amendment')}&nbsp;{requirement?.standard.Amendment}</h4></>}
                            <h4>{t('Section')}&nbsp;{requirement?.section}</h4>

                            <pre className="md-table">
                              <MarkdownView
                                markdown={requirement?.text}
                                options={{ tables: true, emoji: false }}
                              />
                            </pre>
                            { requirement?.topic.length > 0 &&
                              <h4>{t('Topics')}&nbsp;{requirement?.topic && arrayAsBadges(requirement?.topic.map(item => {
                                    return item.title;
                                }) ,'primary')}</h4>
                            }
                            { requirement?.importance.length > 0 &&
                              <h4>{t('Degree of Compliance')}&nbsp;{requirement?.importance && arrayAsBadges(requirement?.importance.split(','),'importance')}</h4>
                            }
                            { requirement?.stakeholder.length > 0 &&
                              <h4>{t('Stakeholders')}&nbsp;{requirement?.stakeholder && arrayAsBadges(requirement?.stakeholder.map(item => {
                                  return item.title;
                              }) ,'primary')}</h4>
                            }
                            { requirement.qmp && requirement.qmp.length > 0 &&
                              <h4>{t('QM Principles')}&nbsp;{requirement?.qmp && arrayAsBadges(requirement?.qmp.map(item => {
                                return item.title;
                              }) ,'primary')}</h4>
                            }
                            { requirement.rme && requirement.rme.length > 0 &&
                              <h4>{t('RM Elements')}&nbsp;{requirement?.rme && arrayAsBadges(requirement?.rme.map(item => {
                                  return item.title;
                              }) ,'primary')}</h4>
                            }

                        </Carousel.Item>,
                      );
                    });

                    setModifiedData(items);

                },
                error => {
                  if (error.message === '401') {
                    authenticationService.logout();
                  }
                }
            );
          }

          retrieveData();

        }, [sortColumn, sortType, page, pageSize])

      return modifiedData;
    }

  const items = useModifiedData(props.sort_column, props.sort_type, props.page, props.page_size )


  useEffect(() => {

    if (props.req_id) {
      //set the carousel index
      requirements.forEach((requirement, i) => {
        if (props.req_id === requirement.id)
          setIndex(i);
      });
    }

  }, [props.req_id, requirements]);

  useEffect(() => {

    if (props.req_id_timestamp) {
      handleShow();
    }

  }, [props.req_id_timestamp]);

  return (
    <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      <Modal
        animation={false}
        className="padding-0"
        show={show }
        onHide={handleClose}
        backdrop="static"
        size="lg"

      >
        { !loadingData ?
        <>
        <Modal.Header closeButton>
          <Modal.Title>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={5000000000000}
          >
            {items}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Close')}
          </Button>
        </Modal.Footer>
        </> :
          <LoadingSpinner/>
        }
      </Modal>
    </>
  );
}

export default RequirementDetailsCarousel
