import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import { SiteConfig }  from '../SiteConfig';
import HubSpotComponent from '../components/HubSpotComponent';
import Helpers from '../components/Helpers'

import RequirementsLinksGrid from '../components/grids/RequirementsLinksGrid';

function Links() {

    const { t } = useTranslation();
    const { id } = useParams();

    const [requirement, setRequirement] = useState(null);

    const {docTypeIdToDocType} = Helpers();

    const receiveRequirement = (req) =>
    {
      setRequirement(req);
    }

    return <div>
            <Helmet>
                <title>{t('Links')}</title>
                <bodyAttributes class={'fill-bg ' + SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Requirements linked to requirement')}: {requirement &&
                    <span> {docTypeIdToDocType(requirement.standard.doc_type)} {requirement.standard.number}<br/>{t('Section')} {requirement.section}</span>
                  }</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col">
              <RequirementsLinksGrid receiveRequirement={receiveRequirement} req_id={id}/>
            </div>
            </div>
            <HubSpotComponent isProductionEnvironment={SiteConfig.isProductionEnvironment}/>
          </div>
}

export default Links;
