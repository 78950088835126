
function ButtonSpinner() {

  return (
    <div className="loader button">
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
};

export default ButtonSpinner;
