import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../helpers/handleResponse';
import { apiService } from './apiService';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    register,
    requestResetPassword,
    resetPassword,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(email, password) {

    let data = new FormData();
    data.append('email',email);
    data.append('password',password);

    const requestOptions = {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        body: data
    };

    return fetch(process.env.REACT_APP_API_URL + '/login', requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function register(name, email, password, reCaptchaToken, sign_up, site_tag) {


    let data = new FormData();
    data.append('name',name);
    data.append('email',email);
    data.append('password',password);
    data.append('token',reCaptchaToken);
    data.append('opted_in', sign_up);
    data.append('referrer', site_tag);

    const requestOptions = {
        method: 'POST',
        //headers: { 'Content-Type': 'application/json' },
        body: data
    };

    return fetch(process.env.REACT_APP_API_URL + '/register', requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function requestResetPassword(email) {

    let data = new FormData();
    data.append('email',email);

    return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/reset-password', data);

    /*
    return fetch(process.env.REACT_APP_API_URL + '/reset-password', data)
        .then(handleResponse)
        .then(response => {
            return response;
        });
        */
}
function resetPassword(token,email,password,confirm_password) {

    let data = new FormData();
    data.append('token',token);
    data.append('email',email);
    data.append('password',password);
    data.append('password_confirmation',confirm_password);

    const requestOptions = {
        method: 'POST',
        body: data
    };

    return fetch(process.env.REACT_APP_API_URL + '/reset/password', requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });

    //return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/reset/password', data);

    /*
    return fetch(process.env.REACT_APP_API_URL + '/reset-password', data)
        .then(handleResponse)
        .then(response => {
            return response;
        });
        */
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
