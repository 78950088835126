import React, { useEffect , useState} from "react";
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../services/authenticationService';


function PrivateRoute({ component: Component, roles, ...rest }) {

    const [currentUser, setCurrentUser] = useState(null);
    const [initialised, setInitialised] = useState(false);

    useEffect(() => {
      
      const unsubscribe = authenticationService.currentUser.subscribe(data => {
        setCurrentUser(data);
        setInitialised(true);
      })
      return unsubscribe;

    },[]);
    return (
        <Route {...rest} render={props => {
            if (initialised && !currentUser) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export { PrivateRoute };
