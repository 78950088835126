import React, { useEffect , useState, useCallback} from "react";
import { useHistory } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import LoadingSpinner from '../spinners/LoadingSpinner';
import ModalSpinner from '../spinners/ModalSpinner';
import ErrorModal from '../modals/ErrorModal';
import { useTranslation } from 'react-i18next';
import MarkdownView from 'react-showdown';
import RequirementDetailsModal from '../modals/RequirementDetailsModal';
import { listsService } from '../../services/listsService';
import { authenticationService } from '../../services/authenticationService';
import Helpers from '../Helpers';

export default function RequirementsSharedGrid(props) {

  const [requirements, setRequirements] = useState([]);
  const [recordsTotal, setRecordsTotal] = useState(null);
  const [selectedReqId, setSelectedReqId] = useState(null);
  const [selectedReqIdTimestamp, setSelectedReqIdTimestamp] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const {docTypeIdToDocType} = Helpers();

  const showRequirementsModal = (req_id) => {
    setSelectedReqId(req_id);
    setSelectedReqIdTimestamp(new Date().valueOf());
  }

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const { receiveListInfo, list_id } = props;

  const retrieveData =  useCallback(async() => {
    setLoadingData(true);
      listsService.getSharedForListId(list_id)
      .then(
          (result) => {
            setInitiallyLoaded(true);
            setLoadingData(false);
            setRequirements(result.requirements);
            setRecordsTotal(result.requirements.length);
            receiveListInfo({listName: result.listName,listOwner: result.listOwner});
          },
          error => {
            if (error.message === '401' ) {
              authenticationService.logout();
            } else if (error.message === '500') {
              setShowErrorModal(true);
            } else if (error.message === '403') {
              history.push('/');
            }
          }
      );
  },[list_id,history,receiveListInfo]);

  useEffect(() => {
    retrieveData();
  }, [retrieveData]);

  return (

      <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      { initiallyLoaded && loadingData &&
        <ModalSpinner/>
      }
      { initiallyLoaded  ?
        <>
        <div className="pb-4">
            { recordsTotal > 0 ?
            <>
              <Table striped bordered hover className="custom-responsive">
                <thead>
                  <tr>
                    <th>{t('#')}</th>
                    <th>{t('Section')}</th>
                    <th>{t('Text')}</th>
                  </tr>
                </thead>
                <tbody>
                {requirements.sort((a, b) => a.standard.number - b.standard.number).map(function(value, index){
                  return( <tr
                      className="pointer"
                      key={ index }
                      onClick={() => showRequirementsModal(value.id)}>
                      <td className="text-nowrap">{docTypeIdToDocType(value.standard.doc_type)} {value.standard.number}</td>
                      <td>{value.section}</td>
                      <td>
                        <span className="md-table">
                          <MarkdownView
                            markdown={value.text}
                            options={{ tables: true, emoji: false }}
                          />
                        </span>
                      </td>

                    </tr>)
                  })
                }
                </tbody>
              </Table>
              <RequirementDetailsModal req_id={selectedReqId} req_id_timestamp={selectedReqIdTimestamp}/>

            </>
            :
              <>{t('No requirements')}.</>
            }
          </div>
        </>
      :
     <LoadingSpinner heightOffset={200}/>
    }

  </>

  );
}
