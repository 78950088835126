import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import { SiteConfig }  from './SiteConfig';

i18n
  .use(HttpApi) // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    ns: [SiteConfig.siteTag],
    defaultNS:  SiteConfig.siteTag,
    backend: {
      loadPath: (SiteConfig.siteTag === 'lbnmas') ? '/lbnmas.json' : process.env.REACT_APP_API_URL + '/locales/{{lng}}/{{ns}}.json' ,
      allowMultiLoading: false,
      crossDomain: true,
      parse: function(data) {
        return JSON.parse(JSON.parse(data));
      }
    }
  }, (err: Error, t: (...params: any[]) => string) => {
  if (err) return console.error(err)
});

export default i18n;
