import React, { useState, useCallback } from "react";
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge'
import { SiteConfig }  from '../SiteConfig';
import HubSpotComponent from '../components/HubSpotComponent';

import RequirementsSelectedListGrid from '../components/grids/RequirementsSelectedListGrid';

function MyLists() {

    const { t } = useTranslation();
    const { id } = useParams();

    const [listName, setListName] = useState(null);
    const [listCount, setListCount] = useState(null);

    const receiveListInfo = useCallback((name,count) =>
    {
      setListName(name);
      setListCount(count);
    },[setListName,setListCount]);

    return <div>
            <Helmet>
                <title>{t('My lists')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>

            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{ listName && <>{ listName } <Badge variant="info">{listCount} {t('results')}</Badge></> } &nbsp;</h1>
                </div>
              </div>
            </div>
              <RequirementsSelectedListGrid receiveListInfo={receiveListInfo} list_id={id}/>
              <HubSpotComponent isProductionEnvironment={SiteConfig.isProductionEnvironment}/>
            </div>

}

export default MyLists;
