
import React  from "react";
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import instructions1 from '../assets/img/instructions_1.png';
import instructions2 from '../assets/img/instructions_2.png';
import instructions3 from '../assets/img/instructions_3.png';

import ContactForm from '../components/forms/ContactForm';
import { SiteConfig }  from '../SiteConfig';
import HubSpotComponent from '../components/HubSpotComponent';

function Home() {
    const { t } = useTranslation();
    //const [currentUser, setCurrentUser] = useState(null);

    /*
    useEffect(() => {
      redirect to home if already logged in
      setCurrentUser( authenticationService.currentUserValue);

    },[]);
    */

    return <div>
            <Helmet>
                <title>{t('Home')}</title>
                <link rel="canonical" href="/" />
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>

            <header className="masthead">
              <div className="container">
                <div className="intro-text  d-flex align-items-center flex-column  justify-content-center">
                  <div className="intro-heading">{t('DocType')} {t('compliance made simple')}</div>
                  { SiteConfig.siteTag !== 'iddrs' &&
                    <p> <a className="text-white" href="http://fenix-insight.com" target="_blank" rel="noreferrer">{t('by Fenix Insight')}.</a></p>
                  }
                  <div className="intro-buttons">
                    <Link className="btn btn-primary btn-xl text-uppercase m-2" to="/search">{t('Search')} {t('DocType')}</Link>
                    <Link className="btn btn-success btn-xl text-uppercase m-2" to="/mylist">{t('My List')} </Link>
                  </div>
                </div>
              </div>
            </header>
            { SiteConfig.siteTag !== 'iddrs' &&
              <>
                <section className="page-section" name="about" id="#about">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <h2 className="section-heading text-uppercase">{t('About Us')}</h2>
                        <h3 className="section-subheading text-muted">{t('About Us Subheading')}</h3>
                      </div>
                    </div>
                    <div className="row embed text-center">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe className="embed-responsive-item"  title="about video" src="https://player.vimeo.com/video/386457431?autoplay=1&loop=1" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className="row text-center">
                      <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                          <i className="fa fa-circle fa-stack-2x text-primary"></i>
                          <i className="fa fa-search fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="service-heading">{t('Search')}</h4>
                        <p className="text-muted">{t('Bullet One')}</p>
                      </div>
                      <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                          <i className="fa fa-circle fa-stack-2x text-primary"></i>
                          <i className="fa fa-star fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="service-heading">{t('Save')}</h4>
                        <p className="text-muted">{t('Bullet Two')}</p>
                      </div>
                      <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                          <i className="fa fa-circle fa-stack-2x text-primary"></i>
                          <i className="fa fa-check fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="service-heading">{t('Check')}</h4>
                        <p className="text-muted">{t('Bullet Three')}</p>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="page-section" name="contact" id="#contact">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 text-center light">
                        <h2 className="section-heading text-uppercase">{t('Contact Us')}</h2>
                        <h3 className="section-subheading text-muted">{t('Contact Us Subheading')}</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                          <ContactForm/>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="page-section" name="howtouse" id="#howtouse">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 text-center">
                        <h2 className="section-heading text-uppercase">{t('How To Title')}</h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <ul className="timeline">
                          <li>
                            <div className="timeline-image">
                              <img className="rounded-circle img-fluid" src={instructions1} alt="1" />
                            </div>
                            <div className="timeline-panel">
                              <div className="timeline-heading">
                                <h4>{t('Step 1')}</h4>
                                <h4 className="subheading">{t('Step One Subheading')}</h4>
                              </div>
                              <div className="timeline-body">
                                <p className="text-muted">{t('Step One Text')}</p>
                              </div>
                            </div>
                          </li>
                          <li className="timeline-inverted">
                            <div className="timeline-image">
                              <img className="rounded-circle img-fluid" src={instructions2} alt="2" />
                            </div>
                            <div className="timeline-panel">
                            <div className="timeline-heading">
                              <h4>{t('Step 2')}</h4>
                              <h4 className="subheading">{t('Step Two Subheading')}</h4>
                            </div>
                            <div className="timeline-body">
                              <p className="text-muted">{t('Step Two Text')}</p>
                            </div>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-image">
                              <img className="rounded-circle img-fluid" src={instructions3} alt="3" />
                            </div>
                            <div className="timeline-panel">
                            <div className="timeline-heading">
                              <h4>{t('Step 3')}</h4>
                              <h4 className="subheading">{t('Step Three Subheading')}</h4>
                            </div>
                            <div className="timeline-body">
                              <p className="text-muted">{t('Step Three Text')}</p>
                            </div>
                            </div>
                          </li>

                          <li className="timeline-inverted">
                            <div className="timeline-image">
                              <h4>{t('Step Bottom Title')}</h4>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            }
            <HubSpotComponent isProductionEnvironment={SiteConfig.isProductionEnvironment}/>
          </div>

}

export default Home;
