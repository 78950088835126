import React from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next';

function ErrorModal(props) {


  const handleClose = () =>
  {
    props.onCloseErrorModal();
  }
  const { t } = useTranslation();

  return (

      <Modal
        animation={false}
        show={props.showerrormodal}
        onHide={handleClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>{t('Something has gone wrong')}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('We are aware of the problem and are working to fix it. Please try again later')}.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>

  );
}

export default ErrorModal
