import React, { useEffect , useState, useCallback} from "react";
import { useHistory } from "react-router-dom";
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ErrorModal from '../modals/ErrorModal';
import ListSaveToFormModal from '../modals/ListSaveToFormModal';
import ListFormModal from '../modals/ListFormModal';
import { useTranslation } from 'react-i18next';
import MarkdownView from 'react-showdown';
import RequirementDetailsModal from '../modals/RequirementDetailsModal';
import { requirementsService } from '../../services/requirementsService';
import { authenticationService } from '../../services/authenticationService';
import { notificationService } from '../../services/notificationService';
import Helpers from '../Helpers';

export default function RequirementLinksGrid(props) {

  const [linkedRequirements, setLinkedRequirements] = useState([]);
  const [requirement, setRequirement] = useState(null);
  const [recordsTotal, setRecordsTotal] = useState(null);
  const [selectedReqId, setSelectedReqId] = useState(null);
  const [selectedReqIdTimestamp, setSelectedReqIdTimestamp] = useState(null);
  const [selectedListReqIds, setSelectedListReqIds] = useState([]);
  const [showListFormModal, setShowListFormModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showListSaveToFormModal, setShowListSaveToFormModal] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const {docTypeIdToDocType} = Helpers();

  const onCloseListFormModalCallback = (reloadData) => {
    if (reloadData)
      setSelectedReqId(selectedReqId);

    setShowListFormModal(false);
    setShowListSaveToFormModal(true);
  }

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const showRequirementsModal = (req_id) => {
    setSelectedReqId(req_id);
    setSelectedReqIdTimestamp(new Date().valueOf());
  }

  const onCloseShowListSaveToFormModalCallback = (reloadData,showAddListModal) => {
    setShowListSaveToFormModal(false);
    setShowListFormModal(showAddListModal);
  }

  const showListSaveToForm = (e,req_id) => {
    e.preventDefault();
    e.stopPropagation();
    setShowListSaveToFormModal(true);
    setSelectedListReqIds([req_id]);
  }

  const req_id = props.req_id;
  const receiveRequirement = props.receiveRequirement;

  const retrieveData  =  useCallback(async () => {

    setLoadingData(true);
    requirementsService.getLinkedRequirementsById(req_id)
    .then(
        (result) => {
            var arrReqs =  [];

            for(var i in result.items)
                arrReqs.push(result.items[i]);

            setInitiallyLoaded(true);
            setLoadingData(false);
            setLinkedRequirements(arrReqs);
            setRequirement(result.cm_req);
            setRecordsTotal(arrReqs.length);
            receiveRequirement(result.cm_req);

            //update local notifications
            notificationService.markNotificationAsRead(req_id);
        },
        error => {

          if (error.message === '401' ) {
            authenticationService.logout();
          } else if (error.message === '500') {
            setShowErrorModal(true);
          } else if (error.message === '403') {
            history.push('/');
          }

        }
    );

  }, [req_id, history,receiveRequirement]);

  useEffect(() => {
    retrieveData();
  },[retrieveData]);


  return (

      <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      <ListSaveToFormModal show={showListSaveToFormModal} req_ids={selectedListReqIds} handleModalOpenClose={onCloseShowListSaveToFormModalCallback} />
      <ListFormModal show={showListFormModal}  handleModalClose={onCloseListFormModalCallback} />
      { initiallyLoaded && !loadingData && 
        <ModalSpinner/>
      }
      { initiallyLoaded  ?
        <>
          <div className=" row">
            <div className="d-md-flex col">
              <div
                className="pointer mb-4 mr-md-4"
                onClick={() => showRequirementsModal(props.req_id)}
                >
                <div className="h4">
                  <h3>{docTypeIdToDocType(requirement?.standard.doc_type)} {requirement?.standard.number} - {requirement?.standard.title}</h3>
                  <h4>{t('Edition')}&nbsp;{requirement?.standard.edition},&nbsp;{t('Amendment')}&nbsp;{requirement?.standard.edition}</h4>
                  <h4>{t('Section')}&nbsp;{requirement?.section}</h4>
                </div>
                <span className="md-table">
                  <MarkdownView
                    markdown={requirement?.text}
                    options={{ tables: true, emoji: false }}
                  />
                </span>
              </div>
              <Button
                className="mb-4 align-self-center"
                variant="primary"
                size="sm"
                onClick={(e) => showListSaveToForm(e,requirement?.id)}
              >
                {t('Save')}
              </Button>
          </div>
        </div>
        <div className="pb-4">
            { recordsTotal > 0 ?
            <>
              <Table striped bordered hover className="custom-responsive">
                <thead>
                  <tr>
                    <th></th>
                    <th>{t('Section')}</th>
                    <th>{t('Text')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {linkedRequirements.sort((a, b) => a.standard.number - b.standard.number).map(function(value, index){
                  return( <tr
                      className="pointer"
                      key={ index }
                      onClick={() => showRequirementsModal(value.id)}>
                      <td className="text-nowrap">{docTypeIdToDocType(value.standard.doc_type)} {value.standard.number}</td>
                      <td>{value.section}</td>
                      <td>
                        <span className="md-table">
                          <MarkdownView
                            markdown={value.text}
                            options={{ tables: true, emoji: false }}
                          />
                        </span>
                      </td>
                      <td>
                        <Button
                          className="mb-4 align-self-center"
                          variant="primary"
                          size="sm"
                          onClick={(e) => showListSaveToForm(e,value.id)}
                        >
                          {t('Save')}
                        </Button>
                      </td>
                    </tr>)
                  })
                }
                </tbody>
              </Table>
              <RequirementDetailsModal req_id={selectedReqId} req_id_timestamp={selectedReqIdTimestamp}/>

            </>
            :
              <>{t('No linked items')}.</>
            }
          </div>
        </>
      :
     <LoadingSpinner heightOffset={200}/>
    }

  </>

  );
}
