import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SiteConfig }  from '../SiteConfig';

function Footer() {

  const { t } = useTranslation();


  return (
    <div className="container d-print-none">
      <div className="col">
        <footer className="footer mt-5 mb-3">
            <div className="row">
              <div className="col-md-5 text-sm-left pt-2">
                { SiteConfig.siteTag !== 'iddrs' &&
                  <span className="copyright">{t('Copyright')} &copy; Fenix Insight Ltd {new Date().getFullYear()}</span>
                }
              </div>
              <div className="col-md-2">
                { SiteConfig.siteTag === 'mineaction' &&
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/mineactionCM" target="_blank" rel="noreferrer">
                        <i className="fa fa-facebook-f fa-2x"></i>
                      </a>
                    </li>
                  </ul>
                }
              </div>
              <div className="col-md-5 text-sm-right pt-2">
                <ul className="list-inline quicklinks">
                  <li className="list-inline-item">
                    <a href="https://fenix-insight.online/info/privacy" target="_blank" rel="noreferrer">{t('Privacy Policy')}</a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://fenix-insight.online/info/tsandcs" target="_blank" rel="noreferrer">{t('Terms of Use')}</a>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/FAQs">{t('FAQs')}</Link>
                  </li>
                </ul>
              </div>
            </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
