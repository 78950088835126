import React, { useEffect } from 'react';


const HubSpotComponent = ({ isProductionEnvironment }) => {



  useEffect(() => {
    if (isProductionEnvironment) {
      const script = document.createElement("script")
      script.src = "//js-eu1.hs-scripts.com/25731783.js";
      script.id = "hs-script-loader";

      document.body.appendChild(script);
    }
  },[isProductionEnvironment])

  return (
    <>

    </>
  )
}

export default HubSpotComponent;
