import { apiService } from './apiService';

export const listsService = {

    getListById,
    getLists,
    newList,
    renameList,
    deleteList,
    getListsForReqId,
    saveRequirementToLists,
    saveAllRequirementsToLists,
    getShareForReqId,
    getSharedForListId
};

function getListById(id) {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/cm_list/list/'+ id);
}

function getLists(doc_type) {

  let data = new FormData();
  data.append('doc_type',doc_type);

  return apiService.apiGet(process.env.REACT_APP_API_URL + '/cm_list/all_lists',data);
}

function newList(list_name, doc_type) {

  let data = new FormData();
  data.append('list_name',list_name);
  data.append('doc_type',doc_type);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list/new',data);
}

function renameList(list_id, list_name) {

  let data = new FormData();
  data.append('list_name',list_name);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list/rename/' + list_id, data);
}

function deleteList(list_id, doc_type) {

  let data = new FormData();
  data.append('doc_type',doc_type);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list/delete/' + list_id, data);
}

function getListsForReqId(id) {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/cm_list/listsforrequirement/'+ id);
}

function saveRequirementToLists(req_ids, list_ids) {

  let data = new FormData();
  data.append('req_ids', req_ids);
  data.append('list_ids', list_ids);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list/save_req', data);
}

function saveAllRequirementsToLists(req_ids, list_ids) {

  let data = new FormData();
  data.append('req_ids', req_ids);
  data.append('list_ids', list_ids);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_list/save_all', data);
}
function getShareForReqId(id) {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/cm_list/share/'+ id);
}

function getSharedForListId(id) {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/cm_list/shared/'+ id);
}
