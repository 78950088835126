import React, { useEffect , useState} from "react";
import Pagination from 'react-bootstrap/Pagination';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

function RequirementsPagination(props)  {

  const [items, setItems] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
      // calculate total pages
    //const totalPages = Math.ceil(props.pages_total / pageSize);
    //debugger;
    let startPage, endPage;
    if (props.pages_total <= 10) {
        // less than 10 total pages so show all
        startPage = 1;
        endPage = props.pages_total;
    } else {
        // more than 10 total pages so calculate start and end pages
        if (props.page <= 6) {
            startPage = 1;
            endPage = 10;
        } else if (props.page + 4 >= props.pages_total) {
            startPage = props.pages_total - 9;
            endPage = props.pages_total;
        } else {
            startPage = props.page - 5;
            endPage = props.page + 4;
        }
    }

    // create an array of pages to ng-repeat in the pager control
    let pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

    let items = [];

    pages.forEach((number, i) => {
      items.push(
        <Pagination.Item
          onClick={e => {
            if (props.onItemClick)
              props.onItemClick(e.target.text);
          }}
          key={number}
          className={number === props.page ? '' : 'visible-desktop'}
          active={number === props.page}>
          {number}
        </Pagination.Item>,
      );
    });

    setItems(items);

  }, [props]);

  return (
      <div
        className="flex-desktop d-print-none"
      >
      <DropdownButton
        className="mr-3"
         variant="primary"
         title={t('No per page') + ' ' + props.page_size}
         onSelect={e => {
           if (props.onPageSizeChange)
             props.onPageSizeChange(e);
         }}
       >
         <Dropdown.Item eventKey="5" active={"5" === props.page_size}>5</Dropdown.Item>
         <Dropdown.Item eventKey="10" active={"10" === props.page_size}>10</Dropdown.Item>
         <Dropdown.Item eventKey="20" active={"20" === props.page_size}>20</Dropdown.Item>
         <Dropdown.Item eventKey="50" active={"50" === props.page_size}>50</Dropdown.Item>
         <Dropdown.Item eventKey="100" active={"100" === props.page_size}>100</Dropdown.Item>
         <Dropdown.Item eventKey="200" active={"200" === props.page_size}>200</Dropdown.Item>

       </DropdownButton>
        <Pagination>


          <Pagination.First
            disabled={props.page === 1}
            onClick={(e) => {
              if (props.onItemClick)
                props.onItemClick(1);
            }}
          />
          <Pagination.Prev
            disabled={props.page === 1}
            onClick={(e) => {
              if (props.onItemClick)
                props.onItemClick(props.page-1);
            }}
          />
          <Pagination.Item
            disabled={props.page === 1}
            onClick={(e) => {
              if (props.onItemClick)
                props.onItemClick(1);
            }}>{1}
          </Pagination.Item>
          <Pagination.Ellipsis
            className="visible-desktop"
           />
          {items}
          <Pagination.Ellipsis
            className="visible-desktop"
          />
          <Pagination.Item
            disabled={props.page === props.pages_total}
            onClick={(e) => {
              if (props.onItemClick)
                props.onItemClick(props.pages_total);
            }}
          >{props.pages_total}</Pagination.Item>
          <Pagination.Next
            disabled={props.page === props.pages_total}
            onClick={(e) => {
              if (props.onItemClick)
                props.onItemClick(props.page+1);
            }}
          />
          <Pagination.Last
            disabled={props.page === props.pages_total}
            onClick={(e) => {
              if (props.onItemClick)
                props.onItemClick(props.pages_total);
            }}
           />
      </Pagination>

    </div>
  )

};


export default RequirementsPagination
