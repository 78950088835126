import React, { useEffect , useState, useRef} from "react";
import { CSVLink } from "react-csv";
import Nav from 'react-bootstrap/Nav'
import { useTranslation } from 'react-i18next';
import { requirementsService } from '../services/requirementsService';
import ButtonSpinner from './spinners/ButtonSpinner';

import { SiteConfig }  from '../SiteConfig';

function MyListCSVDownload({list_id,disabled}) {

  const { t } = useTranslation();

  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(false);

  const csvInstance = useRef();

  useEffect(() => {
    if (data && csvInstance.current && csvInstance.current.link) {
    setTimeout(() => {
      csvInstance.current.link.click();
      setFetching(false);
    });
  }

  }, [data]);


  return (
    <>{disabled}
      <Nav.Link
        className="btn btn-outline-primary"
        disabled={disabled || fetching}
        onClick={async () => {
          if (disabled) {
            return;
          }
          setFetching(true);
          requirementsService.getListExport(list_id,SiteConfig.docType,SiteConfig.siteTag)
          .then(
              (result) => {

                result.headers.forEach((item, i) => {
                  result.headers[i] = t(item);
                });

                setHeaders(result.headers);
                setData(result.data);
              },
              error => {

              }
          );

        }}
      >
        <i className="fa fa-file"></i>&nbsp;{t('Export as CSV')}{fetching && <ButtonSpinner/>}
      </Nav.Link>

      {data &&
        <CSVLink
          data={data}
          headers={headers}
          filename={"my-requirements-list.csv"}
          ref={csvInstance}
          data-interception='off'
        />
      }
    </>
  )
};

export default MyListCSVDownload;
