import React, { useEffect , useState} from "react";
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { authenticationService } from '../services/authenticationService';
import { myListCounterService } from '../services/myListCounterService';
import Notifications from './Notifications';
import mineactionlogo from '../assets/img/mineaction-logo.png';
import ammunitionmanagementlogo from '../assets/img/ammunitionmanagement-logo.png';
import dmaclogo from '../assets/img/DMAC_LOGO_80x80.png';
import bicclogo from '../assets/img/bicc-logo-1.png';
import lbnmaslogo from '../assets/img/LBNMAS-Website-Logo.png';

import NavResize from './NavResize';
import { SiteConfig }  from '../SiteConfig';

function NavBar() {

  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [myListCount, setMyListCount] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const showNotifications = true;

  const logoUrl = () => {

    let retUrl = '';

    switch (SiteConfig.siteTag) {
      case 'mineaction':
        retUrl = mineactionlogo;
        break;
      case 'ammunitionmanagement':
        retUrl = ammunitionmanagementlogo;
        break;
      case 'afghanistan':
        retUrl = dmaclogo;
        break;
      case 'iddrs':
        retUrl = bicclogo;
        break;
      case 'lbnmas':
        retUrl = lbnmaslogo
        break;
      default:
        retUrl = '';
    }
    return retUrl;
  }
  useEffect(() => {
    const unsubscribe = authenticationService.currentUser.subscribe(data => {
      setCurrentUser(data);
    });

    return unsubscribe;

  },[]);

  useEffect(() => {
    const unsubscribe = myListCounterService.currentMyListCount.subscribe(count => {
      setMyListCount(count);
    });

    return unsubscribe;

  },[]);

  useEffect(() => {

    myListCounterService.getMyListCount();
  },[]);

  function logout() {
    myListCounterService.setMyListCount(0);
    authenticationService.logout();
    setExpanded(false);
  }
  return (

    <Navbar  expanded={expanded}  expand="lg" className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
      <div className="container">
        <div className="col d-lg-flex">

        <NavResize/>
        <Navbar.Brand id="logo" href="/" className="navbar-brand js-scroll-trigger"><img src={logoUrl()} alt="logo" /></Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : true)}
          aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            >
            <Nav  className="mr-auto text-uppercase">
              <Link
                onClick={() => setExpanded(false)}
                className="nav-link "
                to="/">{t('Home')} </Link>
              <Link
                onClick={() => setExpanded(false)}
                className="nav-link"
                to="/search">{t('Search')}</Link>
              <Link
                onClick={() => setExpanded(false)}
                className="nav-link pr-4 position-relative"
                data-count={myListCount}
                to="/lists">{t('My Lists')}
              </Link>
              { SiteConfig.siteTag !== 'iddrs' &&
                <>
                  <Link
                    onClick={() => setExpanded(false)}
                    className="nav-link"
                    to="/#contact">{t('Contact')}</Link>
                  <Link
                    onClick={() => setExpanded(false)}
                    className="nav-link"
                    to="/#about">{t('About')} </Link>
                  <Link
                    onClick={() => setExpanded(false)}
                    className="nav-link"
                    to="/#howtouse">{t('How to Use')}</Link>
                  <Link
                    onClick={() => setExpanded(false)}
                    className="nav-link"
                    to="/FAQs">{t('FAQs')}</Link>
                </>
              }
            </Nav>

            {!currentUser ?
                <>
                  <ButtonGroup>
                    <Link onClick={() => setExpanded(false)} className="nav-link btn btn-success " to="/create-account">{t('Create account')}</Link>
                    <Link onClick={() => setExpanded(false)} className="nav-link btn btn-primary " to="/login">{t('Login')}</Link>
                  </ButtonGroup>
                </>
              : <>
                  <span className="user-name"><i className="fa fa-user-circle" aria-hidden="true"></i>{currentUser?.name} </span>
                  {showNotifications &&  SiteConfig.siteTag !== 'iddrs'  && 'lbnmas' !== SiteConfig.siteTag &&
                    <Notifications setNavExpanded={setExpanded}/>
                  }
                  <Button className="nav-link" color="inherit" onClick={logout}>{t('Log out')}</Button>
                </>
            }
            </Navbar.Collapse>
        </div>
      </div>
    </Navbar>

  );
};

export default NavBar;
