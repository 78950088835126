
import React, { useEffect , useState} from "react";
import { Link, useHistory } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import ButtonSpinner from '../spinners/ButtonSpinner';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { authenticationService } from '../../services/authenticationService';

function ForgottenPasswordForm(props) {

  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [successText, setSuccessText] = useState("");
  const history = useHistory();

  useEffect(() => {
    // redirect to home if already logged in
     if (authenticationService.currentUserValue) {
         history.push('/');
     }
   },[history]);



  return (
    <Formik
            initialValues={{
                email: ''
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email(t('Email is invalid'))
                    .required(t('Please enter your email address'))
            })}
            onSubmit={fields => {
              setFeedbackText('');
              setSuccessText('');
              setSubmitting(true);
              // Fetch the accessToken from the server
              authenticationService.requestResetPassword(fields.email)
                  .then(
                      data => {
                        //debugger;
                        setSubmitting(false);
                          setSuccessText(t('We have e-mailed your password reset link! If this does not show up within 2 minutes please check your junk mail folder.'));
                          setFeedbackText('');

                      },
                      error => {
                          setSubmitting(false);
                          setSuccessText('');
                          setFeedbackText(t('There was a problem with your password request, did you enter the correct email address?'));
                      }
                  );
            }}
            >
            {({ errors, status, touched }) => (

                <>
                {feedbackText && <Alert variant="danger">
                  {feedbackText}
                </Alert>}
                {successText &&
                  <Alert variant="success">
                    {successText}
                  </Alert>
                }

                <Form>

                  <div className="form-group">
                      <label htmlFor="email">{t('Email address')}</label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-envelope" aria-hidden="true"></i></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Field
                          name="email"
                          type="text"
                          className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                           placeholder={t('Enter email')}
                         />
                         {props.token}
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </InputGroup>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary mr-2" disabled={submitting}>{t('Send password reset link')}  {submitting && <ButtonSpinner/>}</button>
                  </div>
                  <p>
                    <Link
                      className="mr-3"
                      to="/create-account"
                    >{t('Create account')}</Link>

                  </p>

              </Form>

                </>
            )}
       </Formik>



  );
};

export default ForgottenPasswordForm;
