
import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import ForgottenPasswordForm from '../components/forms/ForgottenPasswordForm';
import { SiteConfig }  from '../SiteConfig';
import HubSpotComponent from '../components/HubSpotComponent';

function ForgottenPassword() {
    const { t } = useTranslation();
    return <div>
            <Helmet>
                <title>{t('Forgotten password')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Forgotten password')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col-md-10 offset-md-1">
                <ForgottenPasswordForm/>
              </div>
            </div>
            <HubSpotComponent isProductionEnvironment={SiteConfig.isProductionEnvironment}/>
          </div>
}

export default ForgottenPassword;
