import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { useHistory  } from 'react-router-dom';
import RequirementsGrid from '../components/grids/RequirementsGrid';

import { SiteConfig }  from '../SiteConfig';
import HubSpotComponent from '../components/HubSpotComponent';

function Search() {

  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div>
      <Helmet>
          <title>{t('Search')}</title>
          <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
      </Helmet>
      <div className="row">
        <div className="col-md-12">
          <RequirementsGrid history={history}/>
        </div>
      </div>
      <HubSpotComponent isProductionEnvironment={SiteConfig.isProductionEnvironment}/>
    </div>
  );
}

export default Search;
