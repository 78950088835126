import React from 'react';



class NavResize extends React.Component {
    componentDidMount() {
      window.addEventListener("scroll", this.resizeHeaderOnScroll);
    }

    resizeHeaderOnScroll() {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 100,
        headerEl = document.getElementById("mainNav");

      if (headerEl) {
        if (distanceY > shrinkOn) {
          headerEl.classList.add("navShrink");
        } else {
          headerEl.classList.remove("navShrink");
        }
      }
    }

    render() {
      return <></>;
    }

  }

  export default NavResize;
