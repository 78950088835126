
import Badge from 'react-bootstrap/Badge'
import { useTranslation } from 'react-i18next';

const Helpers = () => {

  const { t } = useTranslation();

  const docTypeIdToDocType  = (doctypeId) => {

    var retStr = '';

    switch (doctypeId) {
      case 1:
        retStr = "IMAS"
        break;
      case 2:
        retStr = "IATG"
        break;
      case 3:
        retStr = "AMAS"
        break;
      default:
        retStr = '';
    }
    return retStr;

  }

  const badgeVariant = (type) => {

    var variant = '';

    switch (type.trim()) {
      case 'Shall':
        variant = 'danger';
        break;
      case 'Should':
        variant = 'success';
        break;
      case 'May':
          variant = 'info';
          break;
      case 'Informative':
          variant = 'informative';
          break;
      default:
          variant = 'info';
    }

    return variant;

  }
  const arrayAsBadges = (arr,type) => {
    return arr.map((item) => <Badge variant={type === 'importance' ? badgeVariant(item) : type} key={item}>{t(item.trim())}</Badge>)
  }

  const  createMarkup = (str) => {
    return {__html: str};
  }

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const setCookieEU = (c_name,value,exdays) =>
  {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
    document.cookie = c_name + "=" + c_value+"; path=/";
  }

  const getCookieEU = (c_name) =>
  {
    var i,x,y,ARRcookies=document.cookie.split(";");
    for (i=0;i<ARRcookies.length;i++)
    {
      x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
      y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
      x=x.replace(/^\s+|\s+$/g,"");
      if (x===c_name)
      {
          return unescape(y);
      }
    }
  }

   return { badgeVariant, arrayAsBadges, createMarkup, getWindowDimensions, setCookieEU, getCookieEU, docTypeIdToDocType };
}

export default Helpers
