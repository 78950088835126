
import React, { useEffect , useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';

import ButtonSpinner from '../spinners/ButtonSpinner';
import LoadingSpinner from '../spinners/LoadingSpinner'
import { useTranslation } from 'react-i18next';

import { Formik, Field, Form, useFormik } from 'formik';

import * as Yup from "yup";

import { listsService } from '../../services/listsService';
import { authenticationService } from '../../services/authenticationService';
import { myListCounterService } from '../../services/myListCounterService';

import { SiteConfig }  from '../../SiteConfig';

function ListForm(props) {

  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [listName, setListName] = useState("");

  const validationSchema=Yup.object().shape({
     list_name: Yup.string()
         .required(t('List name is required'))
  })

  const formik = useFormik({
     enableReinitialize: true,
     initialValues: {
       list_name: '',
     },
     validationSchema: validationSchema,
     onSubmit: async (values) => {
       setFeedbackText('');
       setSubmitting(true);

       if (!props.list_id) {

         listsService.newList(values.list_name, SiteConfig.docType)
           .then(
               data => {
                 setSubmitting(false);
                 props.onSuccessfulFormSubmit(true);
                 myListCounterService.setMyListCount(data.count);
               },
               error => {
                 setSubmitting(false);
                 setFeedbackText(t('Add list error'));
               }
           );
       } else {
         listsService.renameList(props.list_id,values.list_name)
           .then(
               user => {
                   setSubmitting(false);
                   props.onSuccessfulFormSubmit(true);
               },
               error => {
                   setSubmitting(false);
                   setFeedbackText(t('Rename list error'));
                   //setValidated(false);
               }
           );
       }
     }
   });

   const setFieldValue = formik.setFieldValue;

   useEffect(() => {


    if (!props.list_id) {
      //setList(null);
      setInitiallyLoaded(true);
    } else {

        listsService.getListById(props.list_id)
        .then(
            (result) => {
              setInitiallyLoaded(true);
              setFieldValue('list_name', result.cm_list.list_name);
              setListName(result.cm_list.list_name)
            },
            error => {

              if (error.message === '401' ) {
                authenticationService.logout();
              } else if (error.message === '500') {
                //setShowErrorModal(true);
              } else if (error.message === '403') {
               // history.push('/');
              }

            }
        );
    }
  },[props.list_id, setFieldValue]);

  return (
      <>

      { initiallyLoaded  ?

        <>
          {feedbackText && <Alert variant="danger">
            {feedbackText}
          </Alert>}
          <Formik>
          <Form  onSubmit={formik.handleSubmit}>
            <div className="form-group">
                <label htmlFor="password">{props.list_id ? t('Enter new name for') + ' \'' + listName + '\'' : t('List name') } </label>
                <InputGroup>
                  <Field
                    name="list_name"
                    type="list_name"
                    value={formik.values.list_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={'form-control' + (formik.errors.list_name && formik.touched.list_name ? ' is-invalid' : '')}
                    placeholder={t('List Name')}
                   />
                  {formik.errors.list_name && formik.touched.list_name && <div className="invalid-feedback">{formik.errors.list_name}</div>}
                </InputGroup>
            </div>
            <div className="form-group">
              {t('Your list name will be visible when you share with others.')}
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary w-100" disabled={submitting}>{props.list_id ? t('Rename') : t('Create') } {submitting && <ButtonSpinner/>}</button>
            </div>
        </Form>
        </Formik>
      </>
      :
     <LoadingSpinner fixedHeight={215}/>
    }
    </>

  );
};

export default ListForm;
