import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { SiteConfig }  from '../SiteConfig';
import HubSpotComponent from '../components/HubSpotComponent';

function FAQS() {
    const { t } = useTranslation(); 

    return <div>
            <Helmet>
                <title>{t('FAQS')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Frequently Asked Questions')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col">
              <h4>How do you decide what text to include in an entry?</h4>
<p>Each entry in the database should be a standalone statement that makes immediate and intuitive sense to the user. Where necessary adjacent sentences and bullet lists 
  may be included for clarity. Text may be adjusted using [ ... ] brackets to show any deletions, additions or replacements included to maintain clarity, 
  so long as any adjustments do not change the sense of the statement.</p>
  
  <h4>Why can I see a 'shall' entry that doesn't seem to include the word 'shall'?</h4>
  
  <p>Sometimes in the Lebanon NMAS a word such as 'must' or a phrase such as 'it is required that' is used which strongly implies a 'shall' intent. In those cases, the 
  entry is marked as 'shall' even though that specific word is not used.</p>
  
  <h4>Why do some 'may' entries seem to imply 'something that might happen' rather than 'something 
  that you are allowed to do'?'</h4>
  
  <p>'May' is sometimes used loosely in mine action standards, when 'might' or 'perhaps', could have been better choices. In general, sentences 
  including the word 'may' are captured in the database if the meaning is clearly 'it is permitted to do something' or if the meaning is ambiguous. Instances when 'may' 
  is used in Lebanon NMAS and it is clear that it means 'perhaps' are not tagged as may. If you think we've not got it right in any cases do get in touch.</p>

  <h4>Why do some entries include combinations of shall, should and may?</h4>
  
  <p>The basic policy is that each entry in the database should be a standalone statement that makes immediate and intuitive sense to the user. 'Shall' and 'should' statements 
  should be entered separately unless doing so would compromise the requirement for 'standalone' clarity, or where both words are used in a single sentence. When you find an 
  entry that combines shall, should or may, it usually means that we have assessed it as being clearer or more convenient to users to do it that way.</p>
  
  <h4>What is the difference between entries marked with shall, should or may and those marked as informative?</h4>
  
  <p>Whilst entries marked with shall, should or may indicate a degree of compliance, entries marked as informative do not. The informative tag allows us to capture the remaining 
    text which provides the user with broader advice and information. How are stakeholder associations decided upon?</p>
  
  <h4>How are stakeholder associations decided upon?</h4>
    <p>Stakeholders are associated with an entry on the basis of:</p>
<ul>
  <li>Reference to something that a stakeholder does;</li>
  <li>Reference to a decision that a stakeholder takes;</li>
  <li>An activity or decision that takes place on site (for the 'site' tag).</li>
</ul>

<p>Associations are intended to be 'immediate', that is they relate to direct activity or responsibility links to stakeholders. Aspects that a stakeholder simply 'has an interest in', 
but that they do not do or decide upon directly are not associated with that stakeholder.</p>
<p>Tagging multiple stakeholders because they might perform some of the functions of other 
stakeholder groups from time to time is avoided. The policy for the database is that if a stakeholder at one level chooses to accept the roles and responsibilities of another 
stakeholder level then they should additionally select that other stakeholder tag and review the applicable results and recommendations.</p>

<h4>Why can't I print out lists from the main results page?</h4>

<p>We didn't include that option because our own view was that the print function would only be useful when developing shorter check lists that monitors might want to use during 
inspection visits. Printing off page after page of entries would be unwieldy and environmentally unattractive. You can print lists from your 'my list' page.</p>

<h4>Why can't I 'swipe through' individual entries from the main results page?</h4>

<p>Just because there are usually too many entries to make that an efficient way to access the information. 
In testing we found that it was easier to scroll down the list on the main page, clicking on those that are of most relevance. Close that window when you're ready to move to 
another one and then click on the next one you need to read in detail. You can swipe through individual entries in 'my list'. That's because we found that those shorter, 
more focused lists are the ones that monitors are most likely to use when checking alignment at the practical level.</p>

<h4>Why is there a 'Topic' field as well as a 'Search' field?</h4>

<p>These two fields work together but in slightly different ways. The topic field allows you to query the entire normative text of Lebanon NMAS from a thematic perspective. 'Search' looks for any occurrence of the selected word or phrase 
within the requirement text held in the database. If you search for competence in 'search' you will get every occurrence of that word. If you select the 'competency' topic you 
will get other results, where database analysts can see that the entry clearly relates to concepts of competence, even though the specific word doesn't appear in the text of 
that entry.</p>

<h4>Who developed the Lebanon NMAS digital tool?</h4>
<p>The Lebanon NMAS digital tool was developed by a small team of mine action professionals and IM specialists from the LMAC and Fenix Insight ltd with the support of UNDP and the 
Dutch government. Team members have extensive experience of work in mine action at a practical, project, and international institutional levels, including the drafting of a 
number of national mine action standards and the monitoring of their own and other organisations for alignment with NMAS (and other standards). </p>

              </div>
            </div>
            <HubSpotComponent isProductionEnvironment={SiteConfig.isProductionEnvironment}/>
          </div>
}

export default FAQS;
