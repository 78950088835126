

function ModalSpinner(props) {
  return (
  <div className="modal-spinner d-flex  justify-content-center align-items-center" >
    <div className="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  )
};


export default ModalSpinner;
