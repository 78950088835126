
function SortableTableHeader(props) {

  const sort = field => {
    let sort_type = props.sort_column !== field ? 'asc' : props.sort_type === 'asc' ? 'desc' : 'asc';
    props.onHeaderClick(field,sort_type);
  }
  return (
    <div
      className={'flex-row pointer ' + props.addClass}
      role="columnheader"
      onClick={
      (e) => {
        sort(props.field);
      }}>
      <strong>
        {props.label}
        {props.sort_column === props.field ?
          <>
          { props.sort_type === 'asc' ?
            <i className="fa fa-fw fa-sort-asc"/>
            :
            <i className="fa fa-fw fa-sort-desc"/>
          }
          </>
          : <i className="fa fa-fw fa-sort"/>
        }
      </strong>
    </div>
  )
};

export default SortableTableHeader;
